var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      ref: "drawerRef",
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "statistics:rating:edit",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "template",
        { slot: "other-region" },
        [
          _c(
            "a-steps",
            {
              staticClass: "mb-6",
              attrs: {
                current: _vm.statusMap[_vm.formData.status] - 1,
                size: "small",
                status: _vm.formData.rejectType != 0 ? "error" : "process",
              },
            },
            [
              _c("a-step", { attrs: { title: "HR确认" } }),
              _c("a-step", { attrs: { title: "BOSS确认" } }),
              _c("a-step", { attrs: { title: "人员确认" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {},
        [
          _c("info-row", { attrs: { label: "月份" } }, [
            _vm._v(_vm._s(_vm.formData.month)),
          ]),
          _c("info-row", { attrs: { label: "人员" } }, [
            _vm._v(_vm._s(_vm.formData.nickName)),
          ]),
          _c("info-row", { attrs: { label: "职位" } }, [
            _vm._v(_vm._s(_vm.formData.positionName)),
          ]),
          _c("info-row", { attrs: { label: "合作时长" } }, [
            _vm._v(
              _vm._s(
                _vm.formData.duration > 0 ? _vm.formData.duration : "小于1"
              ) + "个月"
            ),
          ]),
          _c("info-row", { attrs: { label: "任务数量" } }, [
            _vm._v(_vm._s(_vm.formData.taskCount)),
          ]),
          _c("info-row", { attrs: { label: "平均分" } }, [
            _vm._v(_vm._s(_vm.formData.averageScore)),
          ]),
          _c("info-row", { attrs: { label: "等级" } }, [
            _vm._v(_vm._s(_vm.formData.level)),
          ]),
          _c(
            "info-row",
            { attrs: { label: "历史记录" } },
            [
              _c(
                "a-timeline",
                { staticStyle: { "margin-top": "6px", width: "140%" } },
                _vm._l(_vm.logList, function (item, index) {
                  return _c(
                    "a-timeline-item",
                    { key: index, staticStyle: { "font-size": "14px" } },
                    [
                      _vm._v(
                        "（" +
                          _vm._s(item.operationTime) +
                          "）" +
                          _vm._s(item.userName) +
                          _vm._s(" ") +
                          " " +
                          _vm._s(item.content)
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.formData.status != "FINAL_CONFIRM"
            ? _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _vm.formData.status != "HR_CONFIRM"
                    ? _c(
                        "a-button",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value: "statistics:rating:reject",
                              expression: "'statistics:rating:reject'",
                            },
                          ],
                          staticClass: "mr-3",
                          attrs: { type: "danger" },
                          on: { click: _vm.rejectRating },
                        },
                        [_vm._v("驳回")]
                      )
                    : _vm._e(),
                  _vm.userInfo.roles.some(
                    (item) => item.roleCode == "tongchou"
                  ) && _vm.userInfo.id == _vm.formData.userId
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            disabled:
                              _vm.formData.status != "HR_CONFIRM" &&
                              _vm.userInfo.roles.some(
                                (item) => item.roleCode == "hr"
                              ),
                            type: "primary",
                          },
                          on: { click: _vm.confirmRating },
                        },
                        [_vm._v("确认")]
                      )
                    : _vm.userInfo.roles.every(
                        (item) => item.roleCode != "tongchou"
                      )
                    ? [
                        _c(
                          "a-button",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "statistics:rating:confirm",
                                expression: "'statistics:rating:confirm'",
                              },
                            ],
                            attrs: {
                              disabled:
                                _vm.formData.status != "HR_CONFIRM" &&
                                _vm.userInfo.roles.some(
                                  (item) => item.roleCode == "hr"
                                ),
                              type: "primary",
                            },
                            on: { click: _vm.confirmRating },
                          },
                          [_vm._v("确认")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "月份", prop: "month" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入月份", disabled: true },
                    model: {
                      value: _vm.formData.month,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "month", $$v)
                      },
                      expression: "formData.month",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "人员", prop: "nickName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入人员", disabled: true },
                    model: {
                      value: _vm.formData.nickName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "nickName", $$v)
                      },
                      expression: "formData.nickName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "职位", prop: "positionName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入职位", disabled: true },
                    model: {
                      value: _vm.formData.positionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "positionName", $$v)
                      },
                      expression: "formData.positionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "合作时长", prop: "productionCount" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入合作时长" },
                    model: {
                      value: _vm.formData.duration,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "duration", $$v)
                      },
                      expression: "formData.duration",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "任务数量", prop: "taskCount" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入任务数量" },
                    model: {
                      value: _vm.formData.taskCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "taskCount", $$v)
                      },
                      expression: "formData.taskCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "平均分", prop: "averageScore" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入任务数量" },
                    model: {
                      value: _vm.formData.averageScore,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "averageScore", $$v)
                      },
                      expression: "formData.averageScore",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "等级", prop: "level" } },
                [
                  _c(
                    "a-select",
                    {
                      model: {
                        value: _vm.formData.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "level", $$v)
                        },
                        expression: "formData.level",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "专家" } }, [
                        _vm._v("专家"),
                      ]),
                      _c("a-select-option", { attrs: { value: "高级" } }, [
                        _vm._v("高级"),
                      ]),
                      _c("a-select-option", { attrs: { value: "中级" } }, [
                        _vm._v("中级"),
                      ]),
                      _c("a-select-option", { attrs: { value: "低级" } }, [
                        _vm._v("低级"),
                      ]),
                      _c("a-select-option", { attrs: { value: "新人" } }, [
                        _vm._v("新人"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showScoreDetail
        ? _c(
            "div",
            [
              _c("g-title", [_vm._v("评级明细")]),
              _c("rating-table", {
                attrs: {
                  month: _vm.formData.month,
                  taskIds: _vm.formData.taskIds
                    ? _vm.formData.taskIds.split(",")
                    : [],
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }