var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.backCss },
    [
      _c(
        "div",
        { staticClass: "statistics-menu-list" },
        [
          _vm._l(_vm.copyColumns, function (item, index) {
            return [
              item.dataIndex != "evalResult"
                ? _c(
                    "a-dropdown",
                    {
                      key: index,
                      ref: "statistics-" + index,
                      refInFor: true,
                      style: item.isNum ? "" : "display: none;",
                      attrs: { trigger: ["click"], placement: "topCenter" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "statistics" },
                        [
                          _vm._v("统计"),
                          _c("a-icon", { attrs: { type: "caret-up" } }),
                        ],
                        1
                      ),
                      _c(
                        "a-menu",
                        { attrs: { slot: "overlay" }, slot: "overlay" },
                        [
                          item.isNum
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.compute(
                                        "meanNum",
                                        item.dataIndex,
                                        index,
                                        "求和"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            求和\n          ")]
                              )
                            : _vm._e(),
                          item.isNum
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.compute(
                                        "sumNum",
                                        item.dataIndex,
                                        index,
                                        "平均"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            平均\n          ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "a-dropdown",
                    {
                      key: index,
                      ref: "statistics-" + index,
                      refInFor: true,
                      style: item.isNum ? "" : "display: none;",
                      attrs: { trigger: ["click"], placement: "topCenter" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "statistics" },
                        [
                          _vm._v("平均"),
                          _c("a-icon", { attrs: { type: "caret-up" } }),
                        ],
                        1
                      ),
                      _c(
                        "a-menu",
                        { attrs: { slot: "overlay" }, slot: "overlay" },
                        [
                          item.isNum
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.computeEval(
                                        "sumNumEval",
                                        item.dataIndex,
                                        index,
                                        "硬伤"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            硬伤\n          ")]
                              )
                            : _vm._e(),
                          item.isNum
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.computeEval(
                                        "sumNumEval",
                                        item.dataIndex,
                                        index,
                                        "软伤"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            软伤\n          ")]
                              )
                            : _vm._e(),
                          item.isNum
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.computeEval(
                                        "sumNumEval",
                                        item.dataIndex,
                                        index,
                                        "错翻"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            错翻\n          ")]
                              )
                            : _vm._e(),
                          item.isNum
                            ? _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.computeEval(
                                        "sumNumEval",
                                        item.dataIndex,
                                        index,
                                        "修改率"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            修改率\n          ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.tableLoading } },
        [
          _c(
            "vxe-table",
            {
              ref: "xTree",
              attrs: {
                border: "none",
                "row-config": { isCurrent: true, isHover: true },
                "column-config": { resizable: true },
                "footer-method": _vm.footerMethod,
                "tree-config": _vm.treeConfig,
                "filter-config": {
                  remote: true,
                  showIcon: false,
                  /*           iconNone: 'iconfont icon-shaixuan icon',
        iconMatch: 'iconfont icon-shaixuan icon filter-product-btn' */
                },
                data: _vm.tableData,
              },
              on: {
                "checkbox-change": _vm.selectChangeEvent,
                "checkbox-all": _vm.selectChangeEvent,
                "cell-click": _vm.cellClickEvent,
                "toggle-tree-expand": _vm.toggleExpandChangeEvent,
                "filter-visible": _vm.filterVisible,
                "filter-change": _vm.filterChangeEvent,
              },
            },
            _vm._l(_vm.columns, function (record) {
              return _c("vxe-column", {
                key: record.dataIndex,
                attrs: {
                  fixed:
                    record.dataIndex == "name"
                      ? "left"
                      : record.dataIndex == "totalScore"
                      ? ""
                      : "",
                  field: record.dataIndex,
                  title: record.title,
                  minWidth: record.width,
                  "tree-node": record.dataIndex == "name",
                  "show-overflow": "",
                  filters: [],
                },
                scopedSlots: _vm._u(
                  [
                    record.dataIndex == "user"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.chapterSortAndStageName
                                ? [
                                    row.editable
                                      ? _c(
                                          "a-select",
                                          {
                                            staticStyle: {
                                              margin: "-5px 0",
                                              width: "150px",
                                            },
                                            attrs: {
                                              showArrow: "",
                                              placeholder: "选择用户",
                                              "option-filter-prop": "label",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                              change: function ($event) {
                                                return _vm.listEidt(row)
                                              },
                                            },
                                            model: {
                                              value: row.ownerId,
                                              callback: function ($$v) {
                                                _vm.$set(row, "ownerId", $$v)
                                              },
                                              expression: "row.ownerId",
                                            },
                                          },
                                          _vm._l(_vm.allUsers, function (user) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: user.userId,
                                                attrs: {
                                                  label: user.userName,
                                                  value: user.userId,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "30px",
                                                    height: "30px",
                                                    "border-radius": "50%",
                                                    "margin-right": "10px",
                                                  },
                                                  attrs: {
                                                    src: user.avatar,
                                                    alt: "",
                                                  },
                                                }),
                                                _c("span", [
                                                  _vm._v(_vm._s(user.userName)),
                                                ]),
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "30px",
                                                  height: "30px",
                                                  "border-radius": "50%",
                                                  "margin-right": "10px",
                                                },
                                                attrs: {
                                                  src: row.avatar,
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(row.userName) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ],
                                  ]
                                : _vm._e(),
                            ]
                          },
                        }
                      : record.dataIndex == "name"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(row.name) +
                                    _vm._s(
                                      row.platformAndCoordinator &&
                                        row.productSortAndName &&
                                        row.hasChild &&
                                        row.productTotal
                                        ? "（" + row.productTotal + "）"
                                        : ""
                                    )
                                ),
                              ]),
                            ]
                          },
                        }
                      : record.dataIndex == "completeStatus"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.chapterSortAndStageName
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { display: "flex" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c("actions-tasks", {
                                        attrs: {
                                          propsParams: row,
                                          isMyTask: true,
                                          isMyTaskList: true,
                                          isCalendar: true,
                                          loadData: _vm.getTableData,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        }
                      : record.dataIndex == "calcUnits"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.chapterSortAndStageName
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.getCalcUnits(row.calcUnits)
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        }
                      : record.dataIndex == "totalScore"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.platformAndCoordinator &&
                              row.productSortAndName &&
                              row.productTotal > 50
                                ? _c(
                                    "div",
                                    { staticStyle: { width: "200px" } },
                                    [
                                      _c("a-pagination", {
                                        attrs: {
                                          size: "small",
                                          showLessItems: true,
                                          defaultPageSize: 50,
                                          "default-current": 1,
                                          total: row.productTotal - 0,
                                        },
                                        on: {
                                          change: (page, pageSize) => {
                                            _vm.treeTablePageChange(
                                              page,
                                              pageSize,
                                              row
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : row.chapterSortAndStageName
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(row.totalScore || "") +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        }
                      : record.dataIndex == "evalResult"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.chapterSortAndStageName
                                ? [
                                    _c(
                                      "span",
                                      [
                                        _vm._l(
                                          row.evalResult.kpiValueErrors,
                                          function (item, kpiViewindex) {
                                            return [
                                              item.count > 0
                                                ? _c(
                                                    "a-tag",
                                                    { key: kpiViewindex },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            (item.type == "SOFT"
                                                              ? "软伤数"
                                                              : item.type ==
                                                                "HARD"
                                                              ? "硬伤数"
                                                              : item.type ==
                                                                "ERROR"
                                                              ? "错翻数"
                                                              : item.type ==
                                                                "SUPER_HARD"
                                                              ? "超级硬伤数"
                                                              : item.type ==
                                                                "V1_HARD"
                                                              ? "1改硬伤数"
                                                              : item.type ==
                                                                "V2_HARD"
                                                              ? "2改硬伤数"
                                                              : item.type ==
                                                                "MORE_THAN_V2_HARD"
                                                              ? "2改以上硬伤数"
                                                              : item.type ==
                                                                "SUPER_SOFT"
                                                              ? "超级软伤"
                                                              : "") +
                                                              " : " +
                                                              item.count
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                        row.evalResult.hasKpiModifies &&
                                        row.evalResult.kpiModifyRatio > 0
                                          ? _c("a-tag", [
                                              _vm._v(
                                                "\n                修改率 :\n                "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    row.evalResult.textAmount ||
                                                    !row.isLocal
                                                      ? ""
                                                      : "color:red",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      !row.isLocal
                                                        ? row.evalResult
                                                            .kpiModifyRatio +
                                                            "%"
                                                        : row.evalResult
                                                            .textAmount
                                                        ? row.evalResult
                                                            .kpiModifyRatio +
                                                          "%"
                                                        : "X"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          },
                        }
                      : record.dataIndex == "finishStatus"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      row.finishStatus == "ADVANCE"
                                        ? "提前"
                                        : row.finishStatus == "ON_TIME"
                                        ? "按时"
                                        : row.finishStatus == "DELAY"
                                        ? "拖稿"
                                        : ""
                                    ) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        }
                      : record.dataIndex == "deadline"
                      ? {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.editable
                                ? _c("a-date-picker", {
                                    staticStyle: {
                                      margin: "-5px 0",
                                      "min-width": "145px",
                                    },
                                    attrs: {
                                      format: "YYYY-MM-DD HH:mm",
                                      valueFormat: "YYYY-MM-DD HH:mm",
                                      "show-time": { format: "HH:mm" },
                                      placeholder: "请选择截止日期",
                                    },
                                    on: {
                                      ok: function ($event) {
                                        return _vm.listEidt(row)
                                      },
                                    },
                                    model: {
                                      value: row.deadline,
                                      callback: function ($$v) {
                                        _vm.$set(row, "deadline", $$v)
                                      },
                                      expression: "row.deadline",
                                    },
                                  })
                                : [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(row.deadline) +
                                        "\n          "
                                    ),
                                  ],
                            ]
                          },
                        }
                      : record.dataIndex == "stageName"
                      ? {
                          key: "filter",
                          fn: function ({ $panel, column }) {
                            return [
                              _c(
                                "a-spin",
                                {
                                  staticStyle: {
                                    "padding-left": "10px",
                                    "padding-right": "50px",
                                    "min-height": "30px",
                                    "max-height": "200px",
                                    "overflow-y": "auto",
                                  },
                                  attrs: { spinning: _vm.filterLoading },
                                },
                                [
                                  _c("a-tree", {
                                    attrs: {
                                      checkable: "",
                                      "tree-data": _vm.treeData,
                                    },
                                    on: {
                                      select: (selectedKeys, info) => {
                                        _vm.treeSelect(
                                          record.dataIndex,
                                          selectedKeys,
                                          info
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.checkedStageNameKeys,
                                      callback: function ($$v) {
                                        _vm.checkedStageNameKeys = $$v
                                      },
                                      expression: "checkedStageNameKeys",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : record.dataIndex == "settlementDate"
                      ? {
                          key: "filter",
                          fn: function ({ $panel, column }) {
                            return [
                              _c(
                                "a-spin",
                                {
                                  staticStyle: {
                                    "padding-left": "10px",
                                    "padding-right": "50px",
                                    "min-height": "30px",
                                    "max-height": "200px",
                                    "overflow-y": "auto",
                                  },
                                  attrs: { spinning: _vm.filterLoading },
                                },
                                [
                                  _c("a-tree", {
                                    attrs: {
                                      defaultExpandedKeys: _vm.defaultOpenKeys,
                                      checkable: "",
                                      "tree-data": _vm.treeData,
                                    },
                                    on: {
                                      expand: _vm.treeExpand,
                                      select: (selectedKeys, info) => {
                                        _vm.treeSelect(
                                          record.dataIndex,
                                          selectedKeys,
                                          info
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.checkedSettlementDateKeys,
                                      callback: function ($$v) {
                                        _vm.checkedSettlementDateKeys = $$v
                                      },
                                      expression: "checkedSettlementDateKeys",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : null,
                    record.dataIndex == "name"
                      ? {
                          key: "filter",
                          fn: function ({ $panel, column }) {
                            return [
                              _c(
                                "div",
                                { staticStyle: { padding: "10px" } },
                                [
                                  _c("a-input", {
                                    staticStyle: {
                                      width: "150px",
                                      height: "24px",
                                      "line-height": "24px",
                                    },
                                    attrs: { placeholder: "请输入作品名称" },
                                    on: {
                                      pressEnter: function ($event) {
                                        return _vm.inputFilter(record.dataIndex)
                                      },
                                    },
                                    model: {
                                      value: _vm.productName,
                                      callback: function ($$v) {
                                        _vm.productName = $$v
                                      },
                                      expression: "productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "请更换人员", width: "350px", visible: _vm.visible },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "250px" },
              attrs: {
                showArrow: "",
                placeholder: "选择用户",
                "option-filter-prop": "label",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
              model: {
                value: _vm.ownerId,
                callback: function ($$v) {
                  _vm.ownerId = $$v
                },
                expression: "ownerId",
              },
            },
            _vm._l(_vm.allUsers, function (user) {
              return _c(
                "a-select-option",
                {
                  key: user.userId,
                  attrs: { label: user.userName, value: user.userId },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "25px",
                      height: "25px",
                      "border-radius": "50%",
                      "margin-right": "10px",
                      position: "relative",
                      top: "-2px",
                    },
                    attrs: { src: user.avatar, alt: "" },
                  }),
                  _c("span", [_vm._v(_vm._s(user.userName))]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }